<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import Button from 'primevue/button';
    import type { CtxProductVariant } from '@containex/portal-backend-api-client';
    import ProductVariantItem from '@/product/common/components/ProductVariantItem.vue';
    import { ref } from 'vue';

    defineProps<{
        variants: CtxProductVariant[];
        currentRegionId?: string;
        currentZipCode?: string;
    }>();

    const emits = defineEmits<{
        'add-to-cart': [variantId: string];
        'variant-select': [variantId: string];
    }>();

    const { t } = useI18n();

    const shouldShowAllVariants = ref(false);

    function addToCart(variantId: string): void {
        if (variantId === '') {
            return;
        }

        emits('add-to-cart', variantId);
    }

    function selectVariant(variantId: string): void {
        if (variantId === '') {
            return;
        }

        emits('variant-select', variantId);
    }
</script>
<template>
    <div class="variant-list">
        <template v-for="(variant, index) in variants" :key="variant.id">
            <ProductVariantItem
                v-if="index < 3 || shouldShowAllVariants"
                :variant="variant"
                :current-region-id="currentRegionId"
                :current-zip-code="currentZipCode"
                @add-to-cart="addToCart(variant.id ?? '')"
                @variant-select="selectVariant(variant.id ?? '')"
            />
        </template>
        <Button
            v-if="variants.length > 3 && !shouldShowAllVariants"
            link
            icon="pi pi-chevron-down"
            icon-pos="right"
            class="expand-button"
            :label="t('PRODUCT.SHOW_ALL_DEPOTS')"
            @click="shouldShowAllVariants = true"
        />
        <Button
            v-if="shouldShowAllVariants"
            link
            icon="pi pi-chevron-up"
            icon-pos="right"
            class="expand-button"
            :label="t('PRODUCT.SHOW_LESS_DEPOTS')"
            @click="shouldShowAllVariants = false"
        />
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .variant-list {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-3;

        & button:hover {
            background-color: main.$color-background-bluegray;
        }
    }

    .expand-button {
        align-self: start;
    }
</style>

<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { computed } from 'vue';
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import DetailTable from '@/components/DetailTable.vue';
    import { getProductArticleLineTranslation, getProductDimension } from '@/util/productDetailTranslation';

    interface ProductDetails {
        name: string;
        value?: string | number | null;
        unit?: string;
    }

    const props = defineProps<{
        product: CtxProduct;
    }>();

    const { t, te } = useI18n();

    const productDetails = computed<ProductDetails[]>(() => [
        {
            name: 'PRODUCT.CONTAINER_AMOUNT',
            value: props.product.amount_containers,
        },
        {
            name: 'PRODUCT.CONTAINER_TYPE',
            value: props.product.article_class,
        },
        {
            name: 'PRODUCT.PRODUCT_LINE',
            value: getProductArticleLineTranslation(props.product.article_line),
        },
        {
            name: 'PRODUCT.LENGTH_FEET',
            value: props.product.length_feet,
        },
        {
            name: 'PRODUCT.DIMENSION',
            value: getProductDimension(props.product),
        },
        {
            name: 'PRODUCT.WEIGHT',
            value: props.product.weight,
            unit: 'kg',
        },
        {
            name: 'PRODUCT.ELECTRICITY',
            value: props.product.electricity,
        },
        {
            name: 'PRODUCT.COLOR',
            value: props.product.color !== '' ? getTranslatedColorValue(props.product.color) : '',
        },
    ]);

    function getTranslatedColorValue(color: string): string {
        return `${color} ${t(`RAL.${color.replace(' ', '_')}`)}`;
    }

    const productExtensions = computed<ProductDetails[]>(() => [
        {
            name: 'PRODUCT.FLOOR_INSULATION',
            value: withTranslationFallback(
                `PRODUCT.INSULATION.${String(props.product.isolation_floor)}`,
                props.product.isolation_floor ?? ''
            ),
        },
        {
            name: 'PRODUCT.WALL_INSULATION',
            value: withTranslationFallback(
                `PRODUCT.INSULATION.${String(props.product.isolation_wall)}`,
                props.product.isolation_wall ?? ''
            ),
        },
        {
            name: 'PRODUCT.ROOF_INSULATION',
            value: withTranslationFallback(
                `PRODUCT.INSULATION.${String(props.product.isolation_roof)}`,
                props.product.isolation_roof ?? ''
            ),
        },
    ]);

    const productReferences = computed<ProductDetails[]>(() => [
        {
            name: 'PRODUCT.ARTICLE_NUMBER',
            value: props.product.external_id,
        },
        {
            name: 'PRODUCT.ARTICLE_GROUP',
            value: props.product.article_group,
        },
    ]);

    const filteredProductDetails = computed(() =>
        productDetails.value.filter(
            (detail) =>
                detail.value != null &&
                detail.value !== '' &&
                !(detail.name === 'PRODUCT.CONTAINER_AMOUNT' && typeof detail.value === 'number' && detail.value <= 1)
        )
    );
    const filteredProductExtensions = computed(() =>
        productExtensions.value.filter((detail) => detail.value != null && detail.value !== '')
    );
    const filteredProductReferences = computed(() =>
        productReferences.value.filter((detail) => detail.value != null && detail.value !== '')
    );
    const shouldShowAtAll = computed(
        () =>
            filteredProductDetails.value.length +
                filteredProductExtensions.value.length +
                filteredProductReferences.value.length >
            0
    );

    function withTranslationFallback(messageKey: string, fallback: string): string {
        return te(messageKey) ? t(messageKey) : fallback;
    }
</script>
<template>
    <div v-if="shouldShowAtAll" class="technical-data">
        <h2 class="title text-2xl-bold-line-height-auto">{{ t('PRODUCT.TECHNICAL_DATA') }}</h2>
        <DetailTable :product-details="filteredProductDetails" :table-header="t('PRODUCT.PRODUCT_DETAILS')" />
        <DetailTable :product-details="filteredProductExtensions" :table-header="t('PRODUCT.EXTENSIONS')" />
        <DetailTable :product-details="filteredProductReferences" :table-header="t('PRODUCT.REFERENCE')" />
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .title {
        padding-left: main.$spacing-5;
    }

    @include main.for-breakpoint-sm {
        .title {
            padding-left: 0;
        }
    }

    .technical-data {
        display: flex;
        flex-flow: column;
        row-gap: main.$spacing-6;
    }

    .product-details-table {
        width: 100%;
        font-size: main.$font-size-md;
        line-height: 1.2;

        tr {
            display: flex;
            flex-direction: row;
            width: 100%;
            border-bottom: 1px solid #eaeaea;
            padding-top: main.$spacing-4;
            padding-bottom: main.$spacing-4;
            padding-left: main.$spacing-5;
            overflow-wrap: anywhere;

            th {
                width: 50%;
                text-align: left;
                font-weight: 500;
            }

            td {
                width: 50%;
                text-align: start;
            }
        }

        thead tr {
            th {
                font-weight: 700;
            }
        }
    }

    @include main.for-breakpoint-sm {
        .product-details-table {
            tr {
                th {
                    width: 35%;
                }

                td {
                    width: 65%;
                }
            }

            thead tr {
                padding-left: main.$spacing-4;
                background: main.$color-background-table-header;
            }
        }
    }
</style>

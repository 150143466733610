import type { Image } from '@medusajs/medusa/dist';

const IMAGE_NAMING_ORDER = ['perspektive', 'grundriss', 'ansicht'];

interface SortedImages {
    primary: Image[];
    secondary: Image[];
    tertiary: Image[];
    fourth: Image[];
    rest: Image[];
}

export function sortedProductImages(images: Image[]): Image[] {
    const sortedImages: SortedImages = {
        primary: [],
        secondary: [],
        tertiary: [],
        fourth: [],
        rest: [],
    };

    for (const image of images) {
        if (containsNoNamingPattern(image)) {
            sortedImages.primary.push(image);
        }

        if (image.url.toLowerCase().includes('perspektive')) {
            sortedImages.secondary.push(image);
        } else if (image.url.toLowerCase().includes('grundriss')) {
            sortedImages.tertiary.push(image);
        } else if (image.url.toLowerCase().includes('ansicht')) {
            sortedImages.fourth.push(image);
        } else {
            sortedImages.rest.push(image);
        }
    }

    return [
        sortedImages.primary,
        sortedImages.secondary,
        sortedImages.tertiary,
        sortedImages.fourth,
        sortedImages.rest,
    ].flatMap((x) => x);
}

function containsNoNamingPattern(image: Image): boolean {
    for (const key of IMAGE_NAMING_ORDER) {
        if (image.url.toLowerCase().includes(key)) {
            return false;
        }
    }

    return true;
}
